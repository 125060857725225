<template>
  <div class="file-viewer__container d-flex justify-center align-center">
    <v-card
      class="my-12 text-center primary--text mx-8 mx-sm-0"
      width="500"
      outlined
      :loading="loading"
    >
      <div class="file-viewer__logo-wrap d-flex justify-center my-6">
        <logo-image> </logo-image>
      </div>
      <v-card-text v-if="initializing || expired" class="mb-6">
        <span v-if="initializing"> {{ $t('viewMemberFile.retrievingFile') }}</span>
        <span v-else-if="expired">{{ $t('viewMemberFile.errorExpired') }}</span>
      </v-card-text>
      <v-card-text class="error-text pa-0" v-else>
        <div class="px-8 px-sm-16 mb-6">
          <div>{{ $t('viewMemberFile.welcome') }}</div>
          <div class="mt-4">
            {{ $t('viewMemberFile.prompt') }}
          </div>
        </div>
        <div class="px-8 px-sm-16 mb-6">
          <date-picker
            :placeholder="$t('main.dob')"
            :date="dob"
            @updateDate="updateDate"
            :max="now"
            :min="'1901-01-01'"
            :label="$t('main.dob')"
          />
        </div>
      </v-card-text>
      <v-card-actions class="py-0 mb-6 mt-2" v-if="!initializing && !expired">
        <v-spacer></v-spacer>
        <div>
          <v-tooltip top :disabled="isFormComplete">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn @click="onSubmit" color="primary" :disabled="!isFormComplete || loading">
                  {{ $t('input.go') }}
                </v-btn>
              </div>
            </template>
            <ul>
              <li v-for="(error, index) in errorsTooltip" :key="`tooltip-error-${index}`">
                {{ error }}
              </li>
            </ul>
          </v-tooltip>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style lang="scss">
.file-viewer__container {
  height: 100%;
  margin-top: -50px;
}
</style>
<script>
// Packages, utils, constants
import moment from 'moment';
import to from 'await-to-js';
import DATE_FORMAT from '../constants/moment';

// GQL

import GET_FILE_ALIAS from '../graphql/Query/GetFileIdFromPublicAlias.gql';
import GET_FILE from '../graphql/Query/GetUnlockedFile.gql';

export default {
  name: 'ViewMemberFile',
  data() {
    return {
      dob: null,
      expired: false,
      fileId: null,
      initializing: false,
      loading: false,
      useOldFileFetch: false,
    };
  },
  components: {
    DatePicker: () => import('../components/DatePicker.vue'),
    LogoImage: () => import('../components/LogoImage.vue'),
  },
  async mounted() {
    this.loading = true;
    this.initializing = true;
    try {
      this.fileId = await this.getFileId();
    } catch (e) {
      const isExpired = new RegExp('File URL has expired').test(e);
      if (isExpired) {
        this.expired = true;
      } else {
        this.useOldFileFetch = true;
      }
    }
    this.initializing = false;
    this.loading = false;
  },
  computed: {
    errorsTooltip() {
      const errors = [];
      if (!this.dob) {
        errors.push(this.$t('viewMemberFile.tooltip.noDob'));
      }
      return errors;
    },
    fileInfo() {
      const { file } = this.$route.query;
      if (!file) return !!file;
      try {
        const [providerId, publicFileAlias] = file.split('-');

        return !!providerId && !!publicFileAlias && { providerId, publicFileAlias };
      } catch {
        return false;
      }
    },
    isFormComplete() {
      return !!this.dob;
    },
    now() {
      return this.$store.getters.toUTC(moment()).format(DATE_FORMAT);
    },
  },
  methods: {
    getError(errors) {
      try {
        const { graphQLErrors, message } = { ...errors } || { ...errors }[0];

        if (graphQLErrors[0]?.extensions.code === 'PlatformFormattedError') {
          const { message: errMessage } = graphQLErrors[0];
          return errMessage?.replaceAll('Error: ', '').replaceAll('GraphQL error: ', '');
        }
        let errorMessage = message?.replaceAll('Error: ', '').replaceAll('GraphQL error: ', '');

        // Check to see if the file is "not unlocked" and if so, tell member to contact us
        const isLocked = new RegExp('File is not unlocked').test(errorMessage);

        if (isLocked) {
          errorMessage = `${errorMessage} Please contact support.`;
          return errorMessage;
        }
        return this.$t('viewMemberFile.errorGeneral');
      } catch {
        return this.$t('viewMemberFile.errorGeneral');
      }
    },
    async getFileId() {
      const { providerId, publicFileAlias } = this.fileInfo;

      if (!providerId || !publicFileAlias) return null;
      const variables = {
        providerId,
        publicFileAlias,
      };

      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_FILE_ALIAS,
          variables,
          fetchPolicy: 'no-cache',
        })
      );
      if (errors) {
        throw new Error(this.getError(errors));
      }
      const { getFileIdFromPublicFileAlias: fileId } = query?.data;
      return fileId;
    },
    async getFileUrl(fileId) {
      const { providerId } = this.fileInfo;
      const { dob } = this;
      if (!providerId || !fileId || !dob) return null;
      this.loading = true;
      const variables = {
        providerId,
        fileId,
        dateOfBirth: this.$store.getters.toUTC(dob).unix(),
      };
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_FILE,
          variables,
          fetchPolicy: 'no-cache',
        })
      );
      if (errors || !query) {
        throw new Error(this.getError(errors));
      }

      return query?.data?.getUnlockedFile;
    },
    async oldOnSubmit() {
      const { providerId, publicFileAlias: fileId } = this.fileInfo;
      const { dob } = this;
      if (!providerId || !fileId || !dob) return;
      const variables = {
        providerId,
        fileId,
        dateOfBirth: this.$store.getters.toUTC(dob).unix(),
      };
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_FILE,
          variables,
          fetchPolicy: 'no-cache',
        })
      );
      if (errors || !query) {
        throw new Error(this.getError(errors));
      }
      if (query) {
        const { getUnlockedFile: url } = query.data;
        window.location.href = url;
      }
    },
    async onSubmit() {
      this.loading = true;

      try {
        if (this.useOldFileFetch) {
          await this.oldOnSubmit();
        } else {
          const url = await this.getFileUrl(this.fileId);
          if (url) {
            window.location.href = url;
          } else {
            throw new Error(this.$t('viewMemberFile.errorGeneral'));
          }
        }
      } catch (e) {
        this.$store.commit('setNotification', {
          color: 'error',
          showing: true,
          text: e || this.$t('viewMemberFile.error'),
          timeout: 10000,
        });
      }
      this.loading = false;
    },
    updateDate(date) {
      this.dob = date;
    },
  },
};
</script>
